import bizInfoService from '../service/commonAllOk/bizInfoService';
import bizInfoService2 from '../service/allOkMng/bizInfoService';

const DEFAULT_MY_COMPANY_INFO = {
  bizNo: null,
  prstNm: null,
  compNm: '',
  compTel: null,
  fndDt: null,
  korAddr: null,
  idscd: '00000',
  sector: null,
  labYn: false,
  deptYn: false,
  ventureYn: false,
  innobizYn: false,
  noOwn:false,
  patnCnt: null,
  patnValue: null,
  techRate: null,
};

export const state = () => ({
  regions: ['서울', '경기', '인천', '부산', '대구', '광주', '대전', '울산', '세종', '강원', '충북', '충남', '전북', '전남', '경북', '경남', '제주'],
  code: null,
  favorite: null,
  form: {},
  totalCnt: 0,
  bizInfoList: [],
  infiniteId: +new Date(),
  ready: false,
  // myCompanyInfo: {...DEFAULT_MY_COMPANY_INFO},
});

export const mutations = {
  CLEAR_MYINFO(state){
    state.myCompanyInfo = {...DEFAULT_MY_COMPANY_INFO}
  },
  UPDATE(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key];
    });
  },
  UPDATE_COMPANY_INFO(state, payload){
    Object.keys(payload).forEach(key => {
      state.myCompanyInfo[key] = payload[key];
    });
  },
  UPDATE_MYINFO(state, payload) {
    Object.keys(payload).forEach(key => {
      state.myInfo[key] = payload[key];
    });
  },
  UPDATE_MYINFO_EXTDATA(state, payload) {
    Object.keys(payload).forEach(key => {
      state.myInfo.extData[key] = payload[key];
    });
  },
  UPDATE_FORM(state, payload) {
    Object.keys(payload).forEach(key => {
      state.form[key] = payload[key];
    });
  },
  APPEND_BIZ_INFO_LIST(state, payload) {
    state.bizInfoList.push(...payload);
  },
  RESET_DATA(state) {
    state.totalCnt = 0;
    state.bizInfoList = [];
    state.infiniteId += 1;
    state.ready = true;
  },
  UPDATE_EDITMODE(state, payload){
    state.editMode = payload
  }
};

export const actions = {
  fetchBizInfoCodes({ commit }) {
    return bizInfoService.fetchBizInfoCodes(this.$axios).then(({ success, contents }) => {
      if (success) {
        commit('UPDATE', { code: contents });
      }
      return contents;
    });
  },
  fetchFavorite({ commit }, bizNo) {
    return bizInfoService2.getFavorite(this.$axios, bizNo).then(({ success, contents }) => {
      if (success) {
        commit('UPDATE', { favorite: contents });
      }
    });
  },
  fetchNextBizInfoList({ state }) {
    return bizInfoService.fetchBizInfoList(this.$axios, state.form);
  },
  async updateMyInfo({state}, payload){
    return await this.$axios.put('/apiMngAo/users/', payload)
      .then( res => {
        return res.data
      })
  }
};
