<template>
  <div class="popup premium">
    <div class="p_inner">
      <div class="header">
        <div class="h_inner">
          <h1>4대보험료 절감 레포트</h1>
          <div class="btns">
            <button type="button" class="btn down">다운로드</button>
            <button @click="closePopup" type="button" class="btn close">닫기</button>
          </div>
        </div>
      </div>
      <div class="container">
        <section class="cover">
          <div class="top">
            <h2 class="logo">다된다 로고</h2>
          </div>
          <div class="c_cover">
            <h3>4대보험료 절감<br/><span>REPORT</span></h3>
          </div>
          <div class="notice">
            <p>유의사항</p>
            <ul>
              <li>1. 본 리포트에 포함된 모든 자료에 대한 저작권은 (주)유니콘랜치에게 있습니다.</li>
              <li>2. 당사의 사전 승인없이 리포트 전체 또는 일부를 복사, 복제, 배포, 재출판, 변형, 표시, 게시하거나 전송할 수 없습니다.</li>
              <li>3. 문의사항 : <span class="text_darkblue">(주)유니콘랜치</span> 02.2088.1271</li>
            </ul>
          </div>
        </section>
        <section class="area">
          <h2 class="report_tit">4대보험료 절감이란?<span>01</span></h2>
          <div class="a_cont">
            <h3 class="report_tit_ico">4대보험료 절감방향</h3>
            <p>4대보험료 부과 기준은 (월보수액 X 보험요율) 이며, 월보수액은 과세보수액과 비과세 보수액으로 분리됩니다.<br/>
              다된다는 기업의 노무규정 제도 변경 및 비과세근로소득하여 <span class="text_darkblue">합리적인 과세보수액</span>을 <span class="text_darkblue">설정</span>합니다.</p>
          </div>
          <div class="a_cont">
            <h3 class="report_tit_ico">진행 절차</h3>
            <ul class="steps">
              <li class="step circle">
                <p>1. 플랫폼<br/>자동검토</p>
              </li>
              <li class="step circle">
                <p>2. 절감액<br/>산출</p>
              </li>
              <li class="step circle">
                <p>3. 규정정비</p>
              </li>
              <li class="step circle">
                <p>4. 프로그램<br/>셋팅</p>
              </li>
              <li class="step circle">
                <p>5. 매년<br/>업데이트</p>
              </li>
            </ul>
          </div>
          <div class="a_cont">
            <h3 class="report_tit_ico">절감 사례</h3>
            <table class="p_tbl" v-if="$store.state.ui.view !== 'mobile'">
              <colgroup>
                <col width="20%">
                <col width="10%">
                <col width="15%">
                <col width="10%">
                <col width="15%">
                <col width="20%">
                <col width="10%">
              </colgroup>
              <thead>
                <tr>
                  <th>업체명</th>
                  <th>구분</th>
                  <th>업종</th>
                  <th>소재지</th>
                  <th>절감 대상인원(명)</th>
                  <th>연간절감액(원)</th>
                  <th>절감율</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>(주)*니락</td>
                  <td>법인사업자</td>
                  <td>기계 제조업</td>
                  <td>울산</td>
                  <td class="txt_right">137</td>
                  <td class="txt_right">88,014,570</td>
                  <td>14%</td>
                </tr>
                <tr>
                  <td>**라인</td>
                  <td>개인사업자</td>
                  <td>도소매</td>
                  <td>남양주</td>
                  <td class="txt_right">21</td>
                  <td class="txt_right">18,785,490</td>
                  <td>17%</td>
                </tr>
                <tr>
                  <td>**에스(주)</td>
                  <td>법인사업자</td>
                  <td>서비스업</td>
                  <td>전주</td>
                  <td class="txt_right">11</td>
                  <td class="txt_right">6,797,000</td>
                  <td>22%</td>
                </tr>
                <tr>
                  <td>서*미디어(주)</td>
                  <td>법인사업자</td>
                  <td>미디어컨텐츠</td>
                  <td>서울</td>
                  <td class="txt_right">39</td>
                  <td class="txt_right">24,087,870</td>
                  <td>20%</td>
                </tr>
                <tr>
                  <td>대*물류</td>
                  <td>개인사업자</td>
                  <td>물류, 유통업</td>
                  <td>대구</td>
                  <td class="txt_right">35</td>
                  <td class="txt_right">17,568,700</td>
                  <td>14%</td>
                </tr>
              </tbody>
            </table>
            <div class="bx_savings_list" v-else>
              <dl class="list">
                <dt>업체명</dt>
                <dd>(주)*니락</dd>
                <dt>구분</dt>
                <dd>법인사업자</dd>
                <dt>업종</dt>
                <dd>기계 제조업</dd>
                <dt>소재지</dt>
                <dd>울산</dd>
                <dt>절감 대상인원(명)</dt>
                <dd>137</dd>
                <dt>연간절감액(원)</dt>
                <dd>88,014,570</dd>
                <dt>절감율</dt>
                <dd>14%</dd>
              </dl>
              <dl class="list">
                <dt>**라인</dt>
                <dd>(주)*니락</dd>
                <dt>구분</dt>
                <dd>개인사업자</dd>
                <dt>업종</dt>
                <dd>도소매</dd>
                <dt>소재지</dt>
                <dd>남양주</dd>
                <dt>절감 대상인원(명)</dt>
                <dd>21</dd>
                <dt>연간절감액(원)</dt>
                <dd>18,785,490</dd>
                <dt>절감율</dt>
                <dd>17%</dd>
              </dl>
              <dl class="list">
                <dt>**에스(주)</dt>
                <dd>(주)*니락</dd>
                <dt>구분</dt>
                <dd>법인사업자</dd>
                <dt>업종</dt>
                <dd>서비스업</dd>
                <dt>소재지</dt>
                <dd>전주</dd>
                <dt>절감 대상인원(명)</dt>
                <dd>11</dd>
                <dt>연간절감액(원)</dt>
                <dd>6,797,000</dd>
                <dt>절감율</dt>
                <dd>22%</dd>
              </dl>
              <dl class="list">
                <dt>서*미디어(주)</dt>
                <dd>(주)*니락</dd>
                <dt>구분</dt>
                <dd>법인사업자</dd>
                <dt>업종</dt>
                <dd>미디어컨텐츠</dd>
                <dt>소재지</dt>
                <dd>서울</dd>
                <dt>절감 대상인원(명)</dt>
                <dd>39</dd>
                <dt>연간절감액(원)</dt>
                <dd>24,087,870</dd>
                <dt>절감율</dt>
                <dd>20%</dd>
              </dl>
              <dl class="list">
                <dt>대*물류</dt>
                <dd>(주)*니락</dd>
                <dt>구분</dt>
                <dd>개인사업자</dd>
                <dt>업종</dt>
                <dd>물류, 유통업</dd>
                <dt>소재지</dt>
                <dd>대구</dd>
                <dt>절감 대상인원(명)</dt>
                <dd>35</dd>
                <dt>연간절감액(원)</dt>
                <dd>17,568,700</dd>
                <dt>절감율</dt>
                <dd>14%</dd>
              </dl>
            </div>
          </div>
        </section>
        <section class="area">
          <h2 class="report_tit">4대보험료 절감 기대효과<span>02</span></h2>
          <div class="a_cont">
            <h3 class="report_tit_ico">진행 : 기업 취업규칙, 사규, 근로계약 및 급여대장 정비 / 결과 : 4대보험료 및 소득세 절감(비과세 항목 신설)</h3>
            <div class="col2">
              <div class="col">
                <ul class="bx_benefit">
                  <li class="circle"><p>회사</p></li>
                  <li>
                    <ol>
                      <li><p>4대보험료<br/>절감<br/><span>비용절감</span></p></li>
                      <li><p>노무제도<br/>정비<br/><span>노무 리스트 정비</span></p></li>
                    </ol>
                  </li>
                </ul>
                <ul class="bx_benefit emp">
                  <li class="circle"><p>근로자</p></li>
                  <li>
                    <ol>
                      <li><p>4대보험료<br/>절감<br/><span>급여인상효과</span></p></li>
                      <li><p>소득세<br/>절감<br/><span>실수령액 증가</span></p></li>
                    </ol>
                  </li>
                </ul>
              </div>
              <div class="col">
                <div class="top">4대보험료 연 10~20% 절감</div>
                <table class="p_tbl gray">
                  <colgroup>
                    <col width="15%">
                    <col width="30%">
                    <col width="30%">
                    <col width="25%">
                  </colgroup>
                  <thead>
                    <tr>
                      <th>인원</th>
                      <th>TOTAL (원)</th>
                      <th>4대보험료 절감액(원)</th>
                      <th>소득세 절감액(원)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1인</td>
                      <td class="txt_right">800,100</td>
                      <td class="txt_right">477,360</td>
                      <td class="txt_right">322,740</td>
                    </tr>
                    <tr>
                      <td>10인</td>
                      <td class="txt_right">8,001,000</td>
                      <td class="txt_right">4,773,600</td>
                      <td class="txt_right">3,227,400</td>
                    </tr>
                    <tr>
                      <td>30인</td>
                      <td class="txt_right">24,082,200</td>
                      <td class="txt_right">14,320,800</td>
                      <td class="txt_right">9,682,200</td>
                    </tr>
                    <tr>
                      <td>50인</td>
                      <td class="txt_right">40,005,000</td>
                      <td class="txt_right">23,868,000</td>
                      <td class="txt_right">16,137,000</td>
                    </tr>
                    <tr>
                      <td>100인</td>
                      <td class="txt_right">80,010,000</td>
                      <td class="txt_right">47,736,000</td>
                      <td class="txt_right">32,274,000</td>
                    </tr>
                  </tbody>
                </table>
                <div class="bx_savings_list">
                  <dl class="list">
                    <dt>인원</dt>
                    <dd>1인</dd>
                    <dt>TOTAL (원)</dt>
                    <dd>800,100</dd>
                    <dt>4대보험료 절감액(원)</dt>
                    <dd>477,360</dd>
                    <dt>소득세 절감액 (원)</dt>
                    <dd>322,740</dd>
                  </dl>
                  <dl class="list">
                    <dt>인원</dt>
                    <dd>10인</dd>
                    <dt>TOTAL (원)</dt>
                    <dd>8,001,000</dd>
                    <dt>4대보험료 절감액(원)</dt>
                    <dd>4,773,600</dd>
                    <dt>소득세 절감액 (원)</dt>
                    <dd>3,227,400</dd>
                  </dl>
                  <dl class="list">
                    <dt>인원</dt>
                    <dd>30인</dd>
                    <dt>TOTAL (원)</dt>
                    <dd>24,082,200</dd>
                    <dt>4대보험료 절감액(원)</dt>
                    <dd>14,320,800</dd>
                    <dt>소득세 절감액 (원)</dt>
                    <dd>9,682,200</dd>
                  </dl>
                  <dl class="list">
                    <dt>인원</dt>
                    <dd>50인</dd>
                    <dt>TOTAL (원)</dt>
                    <dd>40,005,000</dd>
                    <dt>4대보험료 절감액(원)</dt>
                    <dd>23,868,000</dd>
                    <dt>소득세 절감액 (원)</dt>
                    <dd>16,137,000</dd>
                  </dl>
                  <dl class="list">
                    <dt>인원</dt>
                    <dd>100인</dd>
                    <dt>TOTAL (원)</dt>
                    <dd>80,010,000</dd>
                    <dt>4대보험료 절감액(원)</dt>
                    <dd>47,736,000</dd>
                    <dt>소득세 절감액 (원)</dt>
                    <dd>32,274,000</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="area area_reduction">
          <h2 class="report_tit">4대보험료 절감 검토 결과<span>03</span></h2>
          <div class="a_cont">
            <h3 class="mark">주식회사 유니콘랜치 대상 인원 : <span class="text_darkblue">총23인</span></h3>
            <div class="colb">
              <h3 class="report_tit_ico">회사/근로자 TOTAL 절감액</h3> <span class="txt_gray">(단위: 원)</span>
            </div>
            <div class="bx_reduction">
              <div class="r_top bg_point">
                <h4>연간TOTAL</h4>
                <p><strong>40,000,000</strong>원</p>
                <p class="top_tip rpt">* 소득세 절감액을 포함하지 않은 <b>4대보험료 절감 총액</b>입니다.</p>
                <!-- <div class="right">
                  <p>소득세 절감액을 포함하지 않은 4대보험료 절감 총액입니다.</p>
                </div> -->
              </div>
              <div class="r_cont">
                <ul>
                  <li>
                    <dl>
                      <dt><b>국민연금</b> 절감액</dt>
                      <dd>9,000,000</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt><b>건강보험</b> 절감액</dt>
                      <dd>8,000,000</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt><b>장기요양</b> 절감액</dt>
                      <dd>7,000,000</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt><b>고용보험</b> 절감액</dt>
                      <dd>8,000,000</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt><b>산재보험</b> 절감액</dt>
                      <dd>9,000,000</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt><b>소득세</b> 절감액</dt>
                      <dd>9,000,000</dd>
                    </dl>
                  </li>
                </ul>
              </div>
              <p class="tip">* 소득세 절감액을 포함하지 않은 <b>4대보험료 절감 총액</b> 입니다.</p>
            </div>
            <div class="colb">
              <h3 class="report_tit_ico">회사 TOTAL 절감액</h3> <span class="txt_gray">(단위: 원)</span>
            </div>
            <div class="bx_reduction">
              <div class="r_top">
                <h4>연간TOTAL</h4>
                <p><strong>20,000,000</strong>원</p>
              </div>
              <div class="r_cont">
                <ul>
                  <li>
                    <dl>
                      <dt><b>국민연금</b> 절감액</dt>
                      <dd>9,000,000</dd>
                    </dl>
                    
                  </li>
                  <li>
                    <dl>
                      <dt><b>건강보험</b> 절감액</dt>
                      <dd>9,000,000</dd>
                    </dl>
                    
                  </li>
                  <li>
                    <dl>
                      <dt><b>장기요양</b> 절감액</dt>
                      <dd>9,000,000</dd>
                    </dl>
                    
                  </li>
                  <li>
                    <dl>
                      <dt><b>고용보험</b> 절감액</dt>
                      <dd>9,000,000</dd>
                    </dl>
                    
                  </li>
                  <li>
                    <dl>
                      <dt><b>산재보험</b> 절감액</dt>
                      <dd>9,000,000</dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
            <div class="colb">
              <h3 class="report_tit_ico">근로자 TOTAL 절감액</h3> <span class="txt_gray">(단위: 원)</span>
            </div>
            <div class="bx_reduction">
              <div class="r_top">
                <h4>연간TOTAL</h4>
                <p><strong>20,000,000</strong>원</p>
              </div>
              <div class="r_cont">
                <ul>
                  <li>
                    <dl>
                      <dt><b>국민연금</b> 절감액</dt>
                      <dd>9,000,000</dd>
                    </dl>
                    
                  </li>
                  <li>
                    <dl>
                      <dt><b>건강보험</b> 절감액</dt>
                      <dd>8,000,000</dd>
                    </dl>
                    
                  </li>
                  <li>
                    <dl>
                      <dt><b>장기요양</b> 절감액</dt>
                      <dd>7,000,000</dd>
                    </dl>
                    
                  </li>
                  <li>
                    <dl>
                      <dt><b>고용보험</b> 절감액</dt>
                      <dd>8,000,000</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt><b>산재보험</b> 절감액</dt>
                      <dd>0</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt><b>소득세</b> 절감액</dt>
                      <dd>9,000,000</dd>
                    </dl>
                    
                  </li>
                </ul>
              </div>
            </div>
            <p class="reference">※ 추가 서류 및 정밀 진단을 통하여 최종 절감액은 변동 (증가 및 감소) 될 수 있습니다.</p>
          </div>
        </section>
        <section class="area">
          <h2 class="report_tit">근로자별 절감 예상 검토결과<span>04</span></h2>
          <div class="c_scroll">
            <table class="tbl_amount">
              <thead>
                <tr>
                  <th colspan="4" class="border_right"></th>
                  <th colspan="13" class="border_right">연간 4대보험료 절감 검토 금액</th>
                  <th colspan="7">연간 소득세 및 지방세 절감 검토 금액</th>
                </tr>
                <tr>
                  <th rowspan="2">성명</th>
                  <th colspan="3" class="border_right">월평균 보수금액</th>
                  <th colspan="2">국민연금 보험료<br/>(9%)</th>
                  <th colspan="2">건강보험료<br/>(7.09%)</th>
                  <th colspan="2">장기요양보험료<br/>(건강보험료의 12.81%)</th>
                  <th colspan="2">고용보험료<br/>(1.8%)</th>
                  <th colspan="2">산재보험료<br/>(평균 1%)</th>
                  <th colspan="3" class="border_right">4대보험료 연간 절감 총액</th>
                  <th colspan="3">소득세 절감액</th>
                  <th colspan="3">지방세 절감액</th>
                  <th>소득세절감</th>
                </tr>
                <tr>
                  <th>비과세 신설금액</th>
                  <th>기존</th>
                  <th class="border_right">변경</th>
                  <th>회사<br/>(4.5%)</th>
                  <th>근로자<br/>(4.5%)</th>
                  <th>회사<br/>(3.545%)</th>
                  <th>근로자<br/>(3.545%)</th>
                  <th>회사<br/>(50%)</th>
                  <th>근로자<br/>(50%)</th>
                  <th>회사<br/>(0.9%)</th>
                  <th>근로자<br/>(0.9%)</th>
                  <th>회사<br/>(1%)</th>
                  <th>근로자<br/>(0%)</th>
                  <th>회사</th>
                  <th>근로자</th>
                  <th class="border_right">TOTAL</th>
                  <th>기존</th>
                  <th>변경</th>
                  <th>절감액</th>
                  <th>기존</th>
                  <th>변경</th>
                  <th>절감액</th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="i in 15" :key="i">
                  <td class="txt_center">문**</td>
                  <td>171,086</td>
                  <td>2,181,666</td>
                  <td>2,010,580</td>
                  <td>92,386</td>
                  <td>92,386</td>
                  <td>72,780</td>
                  <td>72,780</td>
                  <td>9,323</td>
                  <td>9,323</td>
                  <td>18,477</td>
                  <td>18,477</td>
                  <td>1,711</td>
                  <td>-</td>
                  <td>194,677</td>
                  <td>192,966</td>
                  <td>387,643</td>
                  <td>25,310</td>
                  <td>19,850</td>
                  <td>65,520</td>
                  <td>2,531</td>
                  <td>1,985</td>
                  <td>6,552</td>
                  <td>72,072</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td class="txt_center">TOTAL</td>
                  <td>252,172</td>
                  <td>5,853,332</td>
                  <td>4,021,160</td>
                  <td>136,172</td>
                  <td>136,172</td>
                  <td>107,274</td>
                  <td>107,274</td>
                  <td>13,742</td>
                  <td>13,742</td>
                  <td>27,234</td>
                  <td>27,234</td>
                  <td>2,522</td>
                  <td>-</td>
                  <td>286,944</td>
                  <td>284,422</td>
                  <td>571,366</td>
                  <td>47,730</td>
                  <td>39,700</td>
                  <td>96,360</td>
                  <td>4,773</td>
                  <td>3,970</td>
                  <td>9,636</td>
                  <td>105,996</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
  
<script>

export default {
  data(){
    return {
    }
  },
  components:{
  },
  methods: {
    closePopup(){
      this.$store.dispatch('ui/setPopupPremium', false);
    },
  },
}

</script>
  