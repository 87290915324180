import * as ACCESS_TYPE from './accessType'

export const unicornranch = {
    path: '/',
    companyId: 'ADM0002',
    companyName: 'BIZWISE',
    serviceName: '비즈와이즈',
    reportName: '비즈와이즈',
    homeUrl:'/',
    logoImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/bizwise-01.png',
    logoImgWrapperWidth:180,
    logoImgMobileWrapperheight:75,
    logoMobileImgPath:'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/bizwise-01.png',
    footer:{
        companyName:'(주) 유니콘랜치',
        prstNm: '전호',
        bizNo: '698-87-02015',
        tel: '02-2088-1271',
        addr: '서울특별시 영등포구 여의대로 108 파크원1, 5층',
        copyright: "Copyright © UnicornRanch. All Rights Reserved."
    },
}

export const taxIntroFooter = {
    companyName:'정원세무회계',
    prstNm: null,
    bizNo: '875-43-00852',
    tel: null,
    addr: '서울특별시 강남구 강남대로62길 3, 2층',
    copyright: "Copyright © JUNGWON TAX ACCOUNTING Co.Ltd, All Rights Reserved."
}
