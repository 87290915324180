import * as landing from '../constants/landing'
const DEFAULT_STATE = {
  url: "https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/allok",
  device: "",
  view: "",
  cert: false,
  premium: false,
  menuState: false,
  loadingState:false,
  InsuranceReport:false,
  popupBizNum:false,
  popupBizNum2:false,
  popupBizNumSearchType:false,
  popupCompanySelect:false,
  popupCompanyFunc:null,
  isAos:false,
  isIos:false,
  popupAlertData: {
    state: false,
    type: null,
    title: null,
    msg: null,
    btn: {
      link: '',
      name: null,
      fnName: null,
    }
  },
  popupBasicData: {
    state: false,
    type: null,
    title: null,
    msg: null,
  },
  skillCredit:false,
  popupMainBiz:false,
};

export default {
  namespaced: true,
  state: () => ({ ...DEFAULT_STATE }),
  mutations: {
    UPDATE_STATE(state, payload) {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key];
      });
    },
  },
  actions: {
    setDevice({ commit }, payload) {
      commit('UPDATE_STATE', { device: payload });
    },
    setView({ commit }, payload) {
      commit("UPDATE_STATE", { view: payload });
    },
    setPopupAlertData({ commit }, payload) {
      commit('UPDATE_STATE', { popupAlertData: payload });
    },
    setPopupBasicData({ commit }, payload) {
      commit('UPDATE_STATE', { popupBasicData: payload });
    },
    setPopupCert({ commit }, payload) {
      commit('UPDATE_STATE', { cert: payload });
    },
    setPopupPremium({ commit }, payload) {
      commit('UPDATE_STATE', { premium: payload });
    },
    setMenuState({ commit }, payload) {
      commit('UPDATE_STATE', { menuState: payload });
    },
    setLoadingState({ commit }, payload) {
      commit('UPDATE_STATE', { loadingState: payload });
    },
    setPopupInsuranceReport({ commit }, payload) {
      commit('UPDATE_STATE', { InsuranceReport: payload });
    },
    openBizNumPopup({commit}, searchType){
      commit('UPDATE_STATE', { popupBizNumSearchType: searchType? searchType : landing.BIZ_NO_SEARCH_TYPE_SERVICE});
      commit('UPDATE_STATE', { popupBizNum: true });
    },
    closeBizNumPopup({commit}){
      commit('UPDATE_STATE', { popupBizNum: false });
    },
    openBizNumPopup2({commit}){
      commit('UPDATE_STATE', { popupBizNum2: true });
    },
    closeBizNumPopup2({commit}){
      commit('UPDATE_STATE', { popupBizNum2: false });
    },
    setSkillCredit({ commit }, payload) {
      commit('UPDATE_STATE', { skillCredit: payload });
    },
    togglePopup({ state, commit }, key ) {
      const onOffState = state[key];
      const payload = {};
      payload[key] = !onOffState;
      commit('UPDATE_STATE', payload);
    }
  },
};
