import { generateQueryParameter } from '~/utils/HttpUtils';

function fetchBizInfoList(axios, form){
  return axios.$get(`/apiCmnAo/biz-info?${generateQueryParameter(form)}`)
}

function fetchBizInfoDetail(axios, pblancId){
  return axios.$get(`/apiCmnAo/biz-info/${pblancId}`)
}

function fetchBizInfoCodes(axios){
  return axios.$get('/apiCmnAo/biz-info/code/all')
}

function fetchBizInfoMatch(axios, form) {
  return axios.$get(`/apiCmnAo/biz-info/match?${generateQueryParameter(form)}`)
}

export default {
  fetchBizInfoList,
  fetchBizInfoDetail,
  fetchBizInfoCodes,
  fetchBizInfoMatch
}
