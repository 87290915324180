<template>
  <aside class="quick_menu">
    <div class="unit">
      <a href="tel:02-2088-1271" class="btn_cs"><b class="tel1">전화문의</b><b class="tel2">02.<br/>2088.1271</b></a>
      <!-- <a class="btn_login" v-show="!$isLogin()" @click="mypage"><b>로그인</b></a>-->
      <a class="btn_login" v-show="logined" @click="fnLogout"><b>로그아웃</b></a>
      <a class="btn_login" v-show="!logined" @click="mypage"><b>로그인&<br/>마이페이지</b></a>
      <a class="btn_login" v-show="logined" @click="mypage"><b>마이페이지</b></a>
      <a class="btn_con" @click="fnGoConsultant"><b>컨설턴트</b></a>
      <a v-if="isMobile && !isApp" class="btn_appdownload" @click="fnAllOkApp"><b>앱다운로드</b></a>
      <a href="https://youtu.be/i50DyLURmYg" target="_blank" class="btn_guide"><b>이용가이드</b></a>
      <!-- <a href="" class="btn_login"><b>마이페이지</b></a>
      <a href="" class="btn_login"><b>마이페이지</b></a>
      <a href="" class="btn_login"><b>로그인&<br/>마이페이지</b></a>
      <a class="btn_con" @click="fnGoConsultant"><b>컨설턴트</b></a>
      <a class="btn_appdownload"><b>앱다운로드</b></a>
      <a href="http://www.namuasset.com/?page_id=7702&mod=list&pageid=1" target="_blank" class="btn_guide"><b>이용가이드</b></a> -->
    </div>
    <button class="btn_top animate__animated" :class="btnTop == true?'animate__fadeIn' : 'animate__fadeOut'" @click="fnMoveTop()"><b>TOP</b></button>
    <ChanelTalk/>
  </aside>
</template>

<script>
import ChanelTalk from "~/components/common/ChanelTalk";
import authMix from "../../mixins/authMix";

export default {
  mixins:[authMix],
  components: {
    ChanelTalk
  },
  data() {
    return {
      btnTop:false,
      quickMenu:false,
      os: null,
      appUrl:{
        android_url: 'intent://allok_login/#Intent;package=com.ur.allokbackoffice;scheme=allok;end',
        ios_url: 'allok://',
        ios_appstore_url: 'https://itunes.apple.com/kr/app/allok/appid'
      },
      allOkAppUrl:{
        // 현재 운영앱 나중에 개발앱으로 변경
        android_url: this.$store.state.env.allOkAPPLink
      },
      isMobile:false,
      isApp:false,
    };
  },
  computed:{
    logined(){
      return this.$isLogin();
    }
  },
  mounted() {
    // Mobile And App Check
    this.isApp = this.$appCheck().isApp;
    this.isMobile = this.$store.state.ui.device === 'mobile';

    // Scroll
    window.addEventListener("scroll", () => {
      if(document.querySelector(".app").classList.contains("main")){
        let target2 = (document.querySelector(".area1").offsetTop );
        target2 -= this.$store.state.ui.view == "pc" ? 55 : 0;
        if(window.scrollY > target2){
          this.btnTop= true;
          this.quickMenu= true;
        }else{
          this.btnTop= false;
          this.quickMenu=false;
        }
      }
    });
  },
  methods: {
    fnLogin(){
      this.$router.push(`/services/socialLogin?selectedService=quick`)
    },
    fnAlert(msg) {
      let data = {
        state: true,
        type: 'alert',
        msg: `${msg}`,
        btn: {
          name: '확인',
        },
      };
      this.$store.dispatch('ui/setPopupAlertData', data);
    },
    async mypage(){
      if(!this.$isLogin())
        this.$router.push('/services/socialLogin?selectedService=mypage');
      else
        this.$router.push('/mypage/inquiry')
    },
    fnLogout(){
      this.fnMixLogout();
      window.location.reload();
    },
    fnMoveTop() {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },
    fnGoConsultant(){
      const view = this.$store.state.ui.view;
      if(['pc','tablet'].includes(view)){
        let backofficeUrl = this.$store.state.env.consultantAdminLink;
        window.open(backofficeUrl, '_blank')
      }else{
        const msg = '컨설턴트 메뉴는\n' +
            'PC 및 태블릿에서만 이용 가능합니다.\n' +
            '(권장환경: PC)'
        this.$fnAlert(msg);
      }
    },
    fnAllOkApp(){
      let allOkMngUrl = this.$store.state.env.allOkMngLink;
      let userAgent = navigator.userAgent.toLowerCase();
      let isAndroid = userAgent.search("android") > -1;
      let isIos = (userAgent.search("iphone") > -1) || (userAgent.search("ipod") > -1) || (userAgent.search("ipad") > -1);
      if ( isAndroid ) {
        location.href = this.allOkAppUrl.android_url;
      }
      else if ( isIos ) {
        const msg = '서비스 준비중입니다.'
        this.fnAlert(msg)
      }
      else{
        window.open(allOkMngUrl, '_blank')
      }
    },
  },
};
</script>
