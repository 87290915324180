<template>
  <nav :class="{'open': this.$store.state.ui.menuState == true}" style="padding-top: 45px;">
  <!-- <nav v-if="this.$store.state.ui.view == 'pc' || this.$store.state.ui.menuState == true" > -->
    <!-- <div class="n_header">
      <div class="row colb">
        <nuxt-link to="" class="btn_home">홈으로 이동</nuxt-link>
        <button class="btn_close" @click="fnCloseNav">닫기</button>
      </div>
      <div class="row">
        <ComboBox
          :combo="{
            list: compDataList
          }"
          num="1"
          :value="myComp?.bizNo"
          @change="fnChange"
        />
        <button type="button" class="btn_login" @click="fnMixLogout">로그아웃</button>
      </div>
    </div> -->
    <ul class="menu">
      <li class="dep1">
        <button class="dep1_menu" :class="{on: selectedDep1 =='세금환급'}" @click="()=>{
          this.selectedDep1 = '세금환급'
          this.$store.dispatch('ui/setMenuState', false);
          this.$router.push('/mypage/tax')
        }">세금환급</button>
        <!-- <button type="button" class="dep1_menu" @click="fnMenuOpen">경정청구</button>
        <div class="dep2">
          <nuxt-link to="" class="dep2_menu">서브 메뉴1</nuxt-link>
          <nuxt-link to="" class="dep2_menu">서브 메뉴2</nuxt-link>
          <nuxt-link to="" class="dep2_menu">서브 메뉴3</nuxt-link>
          <nuxt-link to="" class="dep2_menu">서브 메뉴4</nuxt-link>
        </div> -->
      </li>
      <li class="dep1">
        <button class="dep1_menu" :class="{on: selectedDep1 =='4대보험절감'}" @click="()=>{
          this.selectedDep1 = '4대보험절감'
          this.$store.dispatch('ui/setMenuState', false);
          this.$router.push('/mypage/insured')
        }">4대보험절감</button>
        <!-- <button type="button" class="dep1_menu" @click="fnMenuOpen">4대보험절감</button>
        <div class="dep2">
          <button class="dep2_menu" :class="{on: $store.state.servicesStore.insuranceTab=='result'}" @click="fnInsuranceTab('result')">진단결과</button>
          <button class="dep2_menu" :class="{on: $store.state.servicesStore.insuranceTab=='submit'}" @click="fnInsuranceTab('submit')">서류제출</button>
        </div> -->
      </li>
      <li class="dep1">
        <button class="dep1_menu" :class="{on: selectedDep1 =='고용지원금'}" @click="()=>{
          //fnAlert('<h2>서비스 준비 중</h2>대표님을 위한 더 정확하고 빠른 진단 서비스를 준비 중이니 <br/>조금만 기다려주세요')
          this.selectedDep1 = '고용지원금'
          this.$store.dispatch('ui/setMenuState', false);
          this.$router.push('/mypage/grant')
        }">고용지원금</button>
        <!-- <button type="button" class="dep1_menu" @click="fnMenuOpen">고용지원금</button>
        <div class="dep2">
          <nuxt-link to="" class="dep2_menu">서브 메뉴1</nuxt-link>
          <nuxt-link to="" class="dep2_menu">서브 메뉴2</nuxt-link>
          <nuxt-link to="" class="dep2_menu">서브 메뉴3</nuxt-link>
          <nuxt-link to="" class="dep2_menu">서브 메뉴4</nuxt-link>
        </div> -->
      </li>
      <li class="dep1">
        <button class="dep1_menu" :class="{on: selectedDep1 =='기업대출'}" @click="()=>{
          this.selectedDep1 = '기업대출'
          this.$store.dispatch('ui/setMenuState', false);
          this.$router.push('/mypage/loan')
          }">기업대출</button>
      </li>
      <li class="dep1" v-if="laborMenuUseAble">
        <button class="dep1_menu" :class="{on: selectedDep1 =='온라인노무'}" @click="()=>{
          this.selectedDep1 = '온라인노무'
          this.$store.dispatch('ui/setMenuState', false);
          this.$router.push('/mypage/labor')
        }">온라인노무</button>
      </li>
      <li class="dep1">
        <button class="dep1_menu" :class="{on: selectedDep1 =='지원사업'}" @click="()=>{
          this.selectedDep1 = '지원사업'
          this.$store.dispatch('ui/setMenuState', false);
          this.$router.push('/mypage/support')
        }">지원사업</button>
      </li>
      <li class="dep1">
        <button class="dep1_menu" :class="{on: selectedDep1 =='내정보'}" @click="()=>{
          this.selectedDep1 = '내정보'
          this.$store.dispatch('ui/setMenuState', false);
          this.$router.push('/mypage/myInfo')
        }">내정보</button>
      </li>
    </ul>
  </nav>
</template>

<script>
import ComboBox from "~/components/common/popup/ComboBox.vue";
import authMix from "~/mixins/authMix";
export default {
  mixins:[authMix],
  components: {
    ComboBox,
  },
  props: ["classNm"],
  created() {
    this.laborMenuUseAble = this.$store.state.env.MYPAGE_LABOR_USEABLE ? true : false;
  },
  data() {
    return {
      selectedDep1: '세금환급',
      compNm: null,
      laborMenuUseAble:false,
    };
  },
  computed: {
    myComp() {
      return this.$store.getters['userStore/getMyComp'];
    },
    compList() {
      if(this.$store.state.userStore.myCompList){
        if(this.$store.state.userStore.myCompList.length>0){
          return this.$store.state.userStore.myCompList
        }
      }
      return []
    },
    compDataList() {
      return this.compList.map(c=>{
        if(c.bizNo === this.myComp.bizNo){
          return {
            name: c.compNm,
            value: c.bizNo,
            tax: c.tax,
            insurance: c.insurance,
            employ: c.employ,
            select: true
          }
        }
        return {
          name: c.compNm,
          value: c.bizNo,
          tax: c.tax,
          insurance: c.insurance,
          employ: c.employ
        }
      })
    }
  },
  watch: {
    $route(to, from) {
      if(to.path != from.path) {
        if(this.$route.path=='/mypage/insured'){
          this.selectedDep1 = '4대보험절감'
        } else if(this.$route.path=='/mypage/grant'){
          this.selectedDep1 = '고용지원금'
        } else if(this.$route.path=='/mypage/tax'){
          this.selectedDep1 = '세금환급'
        } else if (this.$route.path == '/mypage/support') {
          this.selectedDep1 = '지원사업';
        } else if(this.$route.path=='/mypage/labor'){
          this.selectedDep1 = '온라인노무'
        } else if(this.$route.path=='/mypage/myInfo'){
          this.selectedDep1 = '내정보'
        } else if(this.$route.path=='/mypage/loan'){
          this.selectedDep1 = '기업대출'
        }
      }
    }
  },
  methods:{
    fnChange(item){
      this.$nextTick(()=>{
        this.value = item.value
        this.$auth_SaveBizNo(item.value);
        this.$store.commit('servicesStore/UPDATE_BIZNO', item.value)
      })
    },
    fnTogglePop() {
      this.Combobox = !this.Combobox;
    },
    fnMenuOpen(e) {
      const target = e.target.parentNode;
      const onState = target.classList.contains('open') ? true : false;
      if (!onState)
        target.classList.add('open');
      else
        target.classList.remove('open');
    },
    fnCloseNav(){
      this.$store.dispatch('ui/setMenuState', false);
    },
    fnAlert(msg) {
      let data = {
        state: true,
        type: 'alert',
        msg: `${msg}`,
        btn: {
          name: '확인',
        },
      };
      this.$store.dispatch('ui/setPopupAlertData', data);
    },
  },
  async mounted(){
    if(this.$route.query&&this.$route.query.infoyou){
      this.selectedDep1 = '내정보'
    }
    if(this.$route.path=='/mypage/insured'){
      this.selectedDep1 = '4대보험절감'
    } else if(this.$route.path=='/mypage/grant'){
      this.selectedDep1 = '고용지원금'
    } else if(this.$route.path=='/mypage/tax'){
      this.selectedDep1 = '세금환급'
    } else if (this.$route.path == '/mypage/support') {
      this.selectedDep1 = '지원사업';
    } else if(this.$route.path=='/mypage/labor'){
      this.selectedDep1 = '온라인노무'
    } else if(this.$route.path=='/mypage/myInfo'){
      this.selectedDep1 = '내정보'
    } else if(this.$route.path=='/mypage/loan'){
      this.selectedDep1 = '기업대출'
    }
  }
};
</script>
