export const BIZ_SUPPORT ='bizSupport' // 지원사업
export const EMPLOY = 'employ'
export const INSURANCE = 'insurance'
export const INSURANCE_INTRO = 'insuranceIntro'
export const TAX = 'tax'
export const TAX_INTRO = 'taxIntro'
export const MY_PAGE = 'mypage'
export const LANDING_HOME =  'landingHome'
export const LOAN =  'loan'
export const LABOR = 'labor'
