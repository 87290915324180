import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e1e024b4 = () => interopDefault(import('../pages/chanelTalk.vue' /* webpackChunkName: "pages/chanelTalk" */))
const _75d36e9a = () => interopDefault(import('../pages/d3/index.vue' /* webpackChunkName: "pages/d3/index" */))
const _208d28ec = () => interopDefault(import('../pages/mainbiz/index.vue' /* webpackChunkName: "pages/mainbiz/index" */))
const _fc469776 = () => interopDefault(import('../pages/WebToS.vue' /* webpackChunkName: "pages/WebToS" */))
const _8c5d0486 = () => interopDefault(import('../pages/WebToS2.vue' /* webpackChunkName: "pages/WebToS2" */))
const _8c40d584 = () => interopDefault(import('../pages/WebToS3.vue' /* webpackChunkName: "pages/WebToS3" */))
const _8c24a682 = () => interopDefault(import('../pages/WebToS4.vue' /* webpackChunkName: "pages/WebToS4" */))
const _05c55c7e = () => interopDefault(import('../pages/app/confirm.vue' /* webpackChunkName: "pages/app/confirm" */))
const _3820d6a8 = () => interopDefault(import('../pages/mainbiz/support/index.vue' /* webpackChunkName: "pages/mainbiz/support/index" */))
const _3d40c2a2 = () => interopDefault(import('../pages/mng/login.vue' /* webpackChunkName: "pages/mng/login" */))
const _4337eba8 = () => interopDefault(import('../pages/mypage/grant/index.vue' /* webpackChunkName: "pages/mypage/grant/index" */))
const _7b77d854 = () => interopDefault(import('../pages/mypage/inquiry.vue' /* webpackChunkName: "pages/mypage/inquiry" */))
const _19d0618c = () => interopDefault(import('../pages/mypage/insured/index.vue' /* webpackChunkName: "pages/mypage/insured/index" */))
const _6c5e0040 = () => interopDefault(import('../pages/mypage/labor/index.vue' /* webpackChunkName: "pages/mypage/labor/index" */))
const _2c293876 = () => interopDefault(import('../pages/mypage/loan/index.vue' /* webpackChunkName: "pages/mypage/loan/index" */))
const _66b65e80 = () => interopDefault(import('../pages/mypage/myInfo/index.vue' /* webpackChunkName: "pages/mypage/myInfo/index" */))
const _96d04842 = () => interopDefault(import('../pages/mypage/support/index.vue' /* webpackChunkName: "pages/mypage/support/index" */))
const _061a1b3b = () => interopDefault(import('../pages/mypage/tax/index.vue' /* webpackChunkName: "pages/mypage/tax/index" */))
const _6ea21c30 = () => interopDefault(import('../pages/services/confirm.vue' /* webpackChunkName: "pages/services/confirm" */))
const _dcd7a144 = () => interopDefault(import('../pages/services/contactInfo.vue' /* webpackChunkName: "pages/services/contactInfo" */))
const _77bb5e42 = () => interopDefault(import('../pages/services/contractBizInfo.vue' /* webpackChunkName: "pages/services/contractBizInfo" */))
const _c15660f2 = () => interopDefault(import('../pages/services/inputBizConfirm.vue' /* webpackChunkName: "pages/services/inputBizConfirm" */))
const _2ae2004a = () => interopDefault(import('../pages/services/inputBizNo.vue' /* webpackChunkName: "pages/services/inputBizNo" */))
const _d0c04504 = () => interopDefault(import('../pages/services/serviceComplete.vue' /* webpackChunkName: "pages/services/serviceComplete" */))
const _aa0021fe = () => interopDefault(import('../pages/services/serviceSelect.vue' /* webpackChunkName: "pages/services/serviceSelect" */))
const _051c8f6c = () => interopDefault(import('../pages/services/socialLogin.vue' /* webpackChunkName: "pages/services/socialLogin" */))
const _1e519102 = () => interopDefault(import('../pages/services/support/index.vue' /* webpackChunkName: "pages/services/support/index" */))
const _58f37211 = () => interopDefault(import('../pages/mainbiz/insurance/intro.vue' /* webpackChunkName: "pages/mainbiz/insurance/intro" */))
const _ffe92e32 = () => interopDefault(import('../pages/mainbiz/labor/intro.vue' /* webpackChunkName: "pages/mainbiz/labor/intro" */))
const _48037546 = () => interopDefault(import('../pages/mainbiz/support/intro.vue' /* webpackChunkName: "pages/mainbiz/support/intro" */))
const _22b42322 = () => interopDefault(import('../pages/mainbiz/tax/intro.vue' /* webpackChunkName: "pages/mainbiz/tax/intro" */))
const _e469c66a = () => interopDefault(import('../pages/mypage/labor/annual-leave.vue' /* webpackChunkName: "pages/mypage/labor/annual-leave" */))
const _6aa03514 = () => interopDefault(import('../pages/mypage/labor/contract.vue' /* webpackChunkName: "pages/mypage/labor/contract" */))
const _7c799466 = () => interopDefault(import('../pages/mypage/labor/on-duty-pay.vue' /* webpackChunkName: "pages/mypage/labor/on-duty-pay" */))
const _7d020e35 = () => interopDefault(import('../pages/mypage/labor/pay-stub.vue' /* webpackChunkName: "pages/mypage/labor/pay-stub" */))
const _7478f593 = () => interopDefault(import('../pages/mypage/labor/payroll.vue' /* webpackChunkName: "pages/mypage/labor/payroll" */))
const _572737c7 = () => interopDefault(import('../pages/mypage/loan/docs.vue' /* webpackChunkName: "pages/mypage/loan/docs" */))
const _2e696964 = () => interopDefault(import('../pages/mypage/loan/skillCredit.vue' /* webpackChunkName: "pages/mypage/loan/skillCredit" */))
const _3a1baa2e = () => interopDefault(import('../pages/mypage/myInfo/confirm.vue' /* webpackChunkName: "pages/mypage/myInfo/confirm" */))
const _369c4d0a = () => interopDefault(import('../pages/services/employ/complete.vue' /* webpackChunkName: "pages/services/employ/complete" */))
const _5665023f = () => interopDefault(import('../pages/services/employ/corpCert.vue' /* webpackChunkName: "pages/services/employ/corpCert" */))
const _8433223c = () => interopDefault(import('../pages/services/employ/indCert.vue' /* webpackChunkName: "pages/services/employ/indCert" */))
const _16f61728 = () => interopDefault(import('../pages/services/employ/indInfo.vue' /* webpackChunkName: "pages/services/employ/indInfo" */))
const _5568502a = () => interopDefault(import('../pages/services/employ/intro.vue' /* webpackChunkName: "pages/services/employ/intro" */))
const _37b4660a = () => interopDefault(import('../pages/services/employ/loading.vue' /* webpackChunkName: "pages/services/employ/loading" */))
const _0205d476 = () => interopDefault(import('../pages/services/employ/report.vue' /* webpackChunkName: "pages/services/employ/report" */))
const _5009dc6c = () => interopDefault(import('../pages/services/employ/research.vue' /* webpackChunkName: "pages/services/employ/research" */))
const _69c8ec2e = () => interopDefault(import('../pages/services/employ/result.vue' /* webpackChunkName: "pages/services/employ/result" */))
const _375fd6e0 = () => interopDefault(import('../pages/services/insurance/checkresult.vue' /* webpackChunkName: "pages/services/insurance/checkresult" */))
const _9437fae4 = () => interopDefault(import('../pages/services/insurance/complete.vue' /* webpackChunkName: "pages/services/insurance/complete" */))
const _54a6907a = () => interopDefault(import('../pages/services/insurance/corpCert.vue' /* webpackChunkName: "pages/services/insurance/corpCert" */))
const _75a091f0 = () => interopDefault(import('../pages/services/insurance/failResult.vue' /* webpackChunkName: "pages/services/insurance/failResult" */))
const _08600e44 = () => interopDefault(import('../pages/services/insurance/indCert.vue' /* webpackChunkName: "pages/services/insurance/indCert" */))
const _326e7e68 = () => interopDefault(import('../pages/services/insurance/indInfo.vue' /* webpackChunkName: "pages/services/insurance/indInfo" */))
const _fa709e32 = () => interopDefault(import('../pages/services/insurance/intro.vue' /* webpackChunkName: "pages/services/insurance/intro" */))
const _00fc5c49 = () => interopDefault(import('../pages/services/insurance/report.vue' /* webpackChunkName: "pages/services/insurance/report" */))
const _38efa4e7 = () => interopDefault(import('../pages/services/intro/insurance.vue' /* webpackChunkName: "pages/services/intro/insurance" */))
const _f0573486 = () => interopDefault(import('../pages/services/intro/labor.vue' /* webpackChunkName: "pages/services/intro/labor" */))
const _6509949c = () => interopDefault(import('../pages/services/intro/support.vue' /* webpackChunkName: "pages/services/intro/support" */))
const _3c96ae10 = () => interopDefault(import('../pages/services/intro/tax.vue' /* webpackChunkName: "pages/services/intro/tax" */))
const _5161f2e8 = () => interopDefault(import('../pages/services/loan/complete.vue' /* webpackChunkName: "pages/services/loan/complete" */))
const _25761946 = () => interopDefault(import('../pages/services/loan/loading.vue' /* webpackChunkName: "pages/services/loan/loading" */))
const _6acf824a = () => interopDefault(import('../pages/services/loan/research.vue' /* webpackChunkName: "pages/services/loan/research" */))
const _fa4b1ce8 = () => interopDefault(import('../pages/services/loan/result.vue' /* webpackChunkName: "pages/services/loan/result" */))
const _23e7b989 = () => interopDefault(import('../pages/services/support/popular.vue' /* webpackChunkName: "pages/services/support/popular" */))
const _1792afb1 = () => interopDefault(import('../pages/services/tax/checkresult.vue' /* webpackChunkName: "pages/services/tax/checkresult" */))
const _3447435d = () => interopDefault(import('../pages/services/tax/complete.vue' /* webpackChunkName: "pages/services/tax/complete" */))
const _540ff892 = () => interopDefault(import('../pages/services/tax/corpCert.vue' /* webpackChunkName: "pages/services/tax/corpCert" */))
const _18a9e22f = () => interopDefault(import('../pages/services/tax/indCert.vue' /* webpackChunkName: "pages/services/tax/indCert" */))
const _4f4867b9 = () => interopDefault(import('../pages/services/tax/indInfo.vue' /* webpackChunkName: "pages/services/tax/indInfo" */))
const _54fdba78 = () => interopDefault(import('../pages/services/tax/intro.vue' /* webpackChunkName: "pages/services/tax/intro" */))
const _758784d8 = () => interopDefault(import('../pages/services/tax/report.vue' /* webpackChunkName: "pages/services/tax/report" */))
const _33fd5350 = () => interopDefault(import('../pages/mainbiz/support/_pblancId.vue' /* webpackChunkName: "pages/mainbiz/support/_pblancId" */))
const _39bd34a6 = () => interopDefault(import('../pages/services/support/_pblancId.vue' /* webpackChunkName: "pages/services/support/_pblancId" */))
const _bf8830f8 = () => interopDefault(import('../pages/auth/_socialType/callback.vue' /* webpackChunkName: "pages/auth/_socialType/callback" */))
const _12c59b01 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _126fc718 = () => interopDefault(import('../pages/_landing/mng/labor/index.vue' /* webpackChunkName: "pages/_landing/mng/labor/index" */))
const _fd156d2c = () => interopDefault(import('../pages/_landing/mng/login.vue' /* webpackChunkName: "pages/_landing/mng/login" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/chanelTalk",
    component: _e1e024b4,
    name: "chanelTalk"
  }, {
    path: "/d3",
    component: _75d36e9a,
    name: "d3"
  }, {
    path: "/mainbiz",
    component: _208d28ec,
    name: "mainbiz"
  }, {
    path: "/WebToS",
    component: _fc469776,
    name: "WebToS"
  }, {
    path: "/WebToS2",
    component: _8c5d0486,
    name: "WebToS2"
  }, {
    path: "/WebToS3",
    component: _8c40d584,
    name: "WebToS3"
  }, {
    path: "/WebToS4",
    component: _8c24a682,
    name: "WebToS4"
  }, {
    path: "/app/confirm",
    component: _05c55c7e,
    name: "app-confirm"
  }, {
    path: "/mainbiz/support",
    component: _3820d6a8,
    name: "mainbiz-support"
  }, {
    path: "/mng/login",
    component: _3d40c2a2,
    name: "mng-login"
  }, {
    path: "/mypage/grant",
    component: _4337eba8,
    name: "mypage-grant"
  }, {
    path: "/mypage/inquiry",
    component: _7b77d854,
    name: "mypage-inquiry"
  }, {
    path: "/mypage/insured",
    component: _19d0618c,
    name: "mypage-insured"
  }, {
    path: "/mypage/labor",
    component: _6c5e0040,
    name: "mypage-labor"
  }, {
    path: "/mypage/loan",
    component: _2c293876,
    name: "mypage-loan"
  }, {
    path: "/mypage/myInfo",
    component: _66b65e80,
    name: "mypage-myInfo"
  }, {
    path: "/mypage/support",
    component: _96d04842,
    name: "mypage-support"
  }, {
    path: "/mypage/tax",
    component: _061a1b3b,
    name: "mypage-tax"
  }, {
    path: "/services/confirm",
    component: _6ea21c30,
    name: "services-confirm"
  }, {
    path: "/services/contactInfo",
    component: _dcd7a144,
    name: "services-contactInfo"
  }, {
    path: "/services/contractBizInfo",
    component: _77bb5e42,
    name: "services-contractBizInfo"
  }, {
    path: "/services/inputBizConfirm",
    component: _c15660f2,
    name: "services-inputBizConfirm"
  }, {
    path: "/services/inputBizNo",
    component: _2ae2004a,
    name: "services-inputBizNo"
  }, {
    path: "/services/serviceComplete",
    component: _d0c04504,
    name: "services-serviceComplete"
  }, {
    path: "/services/serviceSelect",
    component: _aa0021fe,
    name: "services-serviceSelect"
  }, {
    path: "/services/socialLogin",
    component: _051c8f6c,
    name: "services-socialLogin"
  }, {
    path: "/services/support",
    component: _1e519102,
    name: "services-support"
  }, {
    path: "/mainbiz/insurance/intro",
    component: _58f37211,
    name: "mainbiz-insurance-intro"
  }, {
    path: "/mainbiz/labor/intro",
    component: _ffe92e32,
    name: "mainbiz-labor-intro"
  }, {
    path: "/mainbiz/support/intro",
    component: _48037546,
    name: "mainbiz-support-intro"
  }, {
    path: "/mainbiz/tax/intro",
    component: _22b42322,
    name: "mainbiz-tax-intro"
  }, {
    path: "/mypage/labor/annual-leave",
    component: _e469c66a,
    name: "mypage-labor-annual-leave"
  }, {
    path: "/mypage/labor/contract",
    component: _6aa03514,
    name: "mypage-labor-contract"
  }, {
    path: "/mypage/labor/on-duty-pay",
    component: _7c799466,
    name: "mypage-labor-on-duty-pay"
  }, {
    path: "/mypage/labor/pay-stub",
    component: _7d020e35,
    name: "mypage-labor-pay-stub"
  }, {
    path: "/mypage/labor/payroll",
    component: _7478f593,
    name: "mypage-labor-payroll"
  }, {
    path: "/mypage/loan/docs",
    component: _572737c7,
    name: "mypage-loan-docs"
  }, {
    path: "/mypage/loan/skillCredit",
    component: _2e696964,
    name: "mypage-loan-skillCredit"
  }, {
    path: "/mypage/myInfo/confirm",
    component: _3a1baa2e,
    name: "mypage-myInfo-confirm"
  }, {
    path: "/services/employ/complete",
    component: _369c4d0a,
    name: "services-employ-complete"
  }, {
    path: "/services/employ/corpCert",
    component: _5665023f,
    name: "services-employ-corpCert"
  }, {
    path: "/services/employ/indCert",
    component: _8433223c,
    name: "services-employ-indCert"
  }, {
    path: "/services/employ/indInfo",
    component: _16f61728,
    name: "services-employ-indInfo"
  }, {
    path: "/services/employ/intro",
    component: _5568502a,
    name: "services-employ-intro"
  }, {
    path: "/services/employ/loading",
    component: _37b4660a,
    name: "services-employ-loading"
  }, {
    path: "/services/employ/report",
    component: _0205d476,
    name: "services-employ-report"
  }, {
    path: "/services/employ/research",
    component: _5009dc6c,
    name: "services-employ-research"
  }, {
    path: "/services/employ/result",
    component: _69c8ec2e,
    name: "services-employ-result"
  }, {
    path: "/services/insurance/checkresult",
    component: _375fd6e0,
    name: "services-insurance-checkresult"
  }, {
    path: "/services/insurance/complete",
    component: _9437fae4,
    name: "services-insurance-complete"
  }, {
    path: "/services/insurance/corpCert",
    component: _54a6907a,
    name: "services-insurance-corpCert"
  }, {
    path: "/services/insurance/failResult",
    component: _75a091f0,
    name: "services-insurance-failResult"
  }, {
    path: "/services/insurance/indCert",
    component: _08600e44,
    name: "services-insurance-indCert"
  }, {
    path: "/services/insurance/indInfo",
    component: _326e7e68,
    name: "services-insurance-indInfo"
  }, {
    path: "/services/insurance/intro",
    component: _fa709e32,
    name: "services-insurance-intro"
  }, {
    path: "/services/insurance/report",
    component: _00fc5c49,
    name: "services-insurance-report"
  }, {
    path: "/services/intro/insurance",
    component: _38efa4e7,
    name: "services-intro-insurance"
  }, {
    path: "/services/intro/labor",
    component: _f0573486,
    name: "services-intro-labor"
  }, {
    path: "/services/intro/support",
    component: _6509949c,
    name: "services-intro-support"
  }, {
    path: "/services/intro/tax",
    component: _3c96ae10,
    name: "services-intro-tax"
  }, {
    path: "/services/loan/complete",
    component: _5161f2e8,
    name: "services-loan-complete"
  }, {
    path: "/services/loan/loading",
    component: _25761946,
    name: "services-loan-loading"
  }, {
    path: "/services/loan/research",
    component: _6acf824a,
    name: "services-loan-research"
  }, {
    path: "/services/loan/result",
    component: _fa4b1ce8,
    name: "services-loan-result"
  }, {
    path: "/services/support/popular",
    component: _23e7b989,
    name: "services-support-popular"
  }, {
    path: "/services/tax/checkresult",
    component: _1792afb1,
    name: "services-tax-checkresult"
  }, {
    path: "/services/tax/complete",
    component: _3447435d,
    name: "services-tax-complete"
  }, {
    path: "/services/tax/corpCert",
    component: _540ff892,
    name: "services-tax-corpCert"
  }, {
    path: "/services/tax/indCert",
    component: _18a9e22f,
    name: "services-tax-indCert"
  }, {
    path: "/services/tax/indInfo",
    component: _4f4867b9,
    name: "services-tax-indInfo"
  }, {
    path: "/services/tax/intro",
    component: _54fdba78,
    name: "services-tax-intro"
  }, {
    path: "/services/tax/report",
    component: _758784d8,
    name: "services-tax-report"
  }, {
    path: "/mainbiz/support/:pblancId",
    component: _33fd5350,
    name: "mainbiz-support-pblancId"
  }, {
    path: "/services/support/:pblancId",
    component: _39bd34a6,
    name: "services-support-pblancId"
  }, {
    path: "/auth/:socialType?/callback",
    component: _bf8830f8,
    name: "auth-socialType-callback"
  }, {
    path: "/",
    component: _12c59b01,
    name: "index"
  }, {
    path: "/:landing/mng/labor",
    component: _126fc718,
    name: "landing-mng-labor"
  }, {
    path: "/:landing/mng/login",
    component: _fd156d2c,
    name: "landing-mng-login"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
