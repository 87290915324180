<template>
  <nav :class="{'open': this.$store.state.ui.menuState == true}" style="z-index: 70">
  <!-- <nav v-if="this.$store.state.ui.view == 'pc' || this.$store.state.ui.menuState == true" > -->
    <header>
      <div class="inner">
        <div class="left" style="width: 100%; padding: 14px 0px">
          <h1 class="logo" :style="mixBackgroundStyle" style="background-position-x: 0%; background-size: contain !important;" @click="mxiFnGoHome">다된다 로고</h1>
        </div>
        <div class="right">
          <button class="btn_close" @click="fnCloseNav">닫기</button>
        </div>
      </div>
    </header>
    <div class="menu">
      <nuxt-link :class="{on:mixFnCheckOn('support')}" to="" @click.native="mixFnSupport">지원사업</nuxt-link>
      <nuxt-link :class="{on:mixFnCheckOn('tax')}" to="" @click.native="mixFnTaxIntro">세금환급</nuxt-link>
      <nuxt-link :class="{on:mixFnCheckOn('insurance')}" to="" @click.native="mixFnInsuranceIntro">4대보험료 절감</nuxt-link>
      <nuxt-link :class="{on:mixFnCheckOn('loan')}" to="" @click.native="mixFnLoan">기업대출</nuxt-link>
      <nuxt-link :class="{on:mixFnCheckOn('employ')}" to="" @click.native="mixFnEmploy">고용지원금</nuxt-link>
      <nuxt-link :class="{on:mixFnCheckOn('labor')}" to="" @click.native="mixFnLaborIntro">온라인 노무관리</nuxt-link>
    </div>
  </nav>
</template>

<script>
import serviceMix from "../../../mixins/partner/serviceMix";
import headerMix from "../../../mixins/partner/headerMix";

export default {
  mixins:[serviceMix, headerMix],
  components: {
  },
  props: ["classNm"],
  data() {
    return {
      eventbanner: false,
    };
  },
  methods:{
    fnAlert(){
      this.$fnAlert('서비스 준비중입니다.')
    },
    fnMenuOpen(e) {
      const target = e.target.parentNode;
      const onState = target.classList.contains('open') ? true : false;
      if (!onState)
        target.classList.add('open');
      else
        target.classList.remove('open');
    },
    fnCloseNav(){
      this.$store.dispatch('ui/setMenuState', false);
    }
  }
};
</script>

<style scoped>
.menu .on{
  color: #3497FD;
}
.menu a{
  display: block;
  position: relative;
}
.menu a:hover{
  color: #3497FD;
}
.menu a:before{
  background: transparent linear-gradient(90deg, #5773FF 0%, #3497FD 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  width: 0;
}
.menu a:hover:before{
  width: 100%;
}
</style>
