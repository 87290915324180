import validationPlugin from "./validationPlugin";
import tokenSessionStoragePlugin from "./tokenSessionStoragePlugin";
import * as keys from '../constants/storageKey';
import * as accessType from '../constants/accessType';

/**
 * fnAlert - 팝업 얼럿
 * fnConfirm - 팝업 컨펌
 * fnClosePopup - 팝업(얼럿/컨펌) 닫기
 * getCompInfo - 세션스토어에 저장된 검색된 회사정보 ( bizNo, compNm )을 가져온다.
 * fnSaveLog - 서버에 로깅
 * fnSaveErrorLog - 서버에 에러 로깅
 * appCheck - 앱 여부 체크 (ios인지 aos인지 포함)
 * isCorporation - 법인 / 개인사업자인지 구분
 * fnFilenameGenerator - 중복파일명을 찾아서 넘버링 해줌
 * fnValidateBizNo - 사업자번호 검증
 * fnFormatDate - 날짜포맷 yyyy.mm.dd
 * convertCompanyInfo - API를 통해서 받은 값을 현 프로젝트에 맞게 컨버팅한다.
 * checkMacOS - Mac 인지 확인
 * getMyCompIdx - 내 회사 목록중에 선택된 인덱스를 가져온다.
 * setMyCompIdx - 회사 목록중에 선택된 회사의 인덱스를 저정한다.
 * isPcAndHomePath - 피씨로 /index.vue 접속여부 체크
 */

// 프레임워크 내에서 불러서 사용할 수 있도록 생성

export default (context, inject) => {
  const { store, app, route } = context;

  inject('goHome', ()=> {
    let homeLink = '/'
    if (process.client) {
      const stringfiedData = localStorage.getItem(keys.partnerInfoKey);
      if(stringfiedData){
        homeLink = JSON.parse(stringfiedData).homeUrl;
        /**
         * 컨설턴트 코드로 접속한 경우
         * 주소 뒤에 컨설턴트 코드를 붙여서 리턴한다.
         */
        const consultantInfo = localStorage.getItem(keys.ConsultantInfo);
        if(consultantInfo){
          const parsedConsultantInfo = JSON.parse(consultantInfo);
          const {fcCd} = parsedConsultantInfo;
          if(fcCd){
            homeLink = homeLink+fcCd;
          }
        }
      }
    }
    // console.log('homelink', homeLink);
    return homeLink
  });

  inject('checkMacOS', ()=> {
    const userOs = navigator.userAgent.replace(/ /g, '').toLowerCase()
    if( userOs.match(/macintosh/i) == "macintosh") {
      return true;
    }else{
      return false;
    }
  });

  inject('accessType', ()=>{
    if (process.client) {
      const isAos = store.state.ui.isAos;
      const isIos = store.state.ui.isIos;
      const isApp = isAos || isIos;
      const pcType = store.state.ui.view == "pc";
      if ( isApp )
        return accessType.APP
      else
        return pcType ? accessType.LANDING : accessType.MOBILE;
    }
    return null;
  });

  inject('isLogin', ()=> {
    if(process.client){
      const accessToken = tokenSessionStoragePlugin.getAccessToken(process.client);
      if(!accessToken){
        return false;
      } else{
        return true;
      }
    }
    return false;
  });

  inject('getMyCompIdx', ()=>{
    let savedMyCompIdx = 0;
    const compList = store.state.userStore.myCompList;

    // 인덱스가 회사 목록 크기보다 큰경우 ( 비정상 케이스 )
    if ( compList.length < (savedMyCompIdx+1)){
      // 기본 값(0)으로 설정해준다.
      savedMyCompIdx = 0;
    }
    return savedMyCompIdx;
  });

  inject('setMyCompIdx', (idx)=>{

  });

  inject('validator', ( type, value )=> {
    return validationPlugin(type, value);
  });

  inject('fnValidateBizNo', (bizNo) => {
    let message = null;
    if(!bizNo){
      message = '<h2>사업자번호를 입력해주세요.</h2>';
    }
    else if (isNaN(bizNo)){
      message = '<h2>사업자번호 입력오류</h2>숫자만 입력해주세요'
    }
    else if (bizNo === null || bizNo.length != 10){
      message = '<h2>사업자번호 입력오류</h2>10자리 숫자의<br/>사업자번호를 입력해주세요'
    }
    return {
      success : message ? false: true,
      message
    }
  });

  inject('getUserInfo', ()=>{
    return {
      userNo : sessionStorage.getItem('user_no'),
      userNm : sessionStorage.getItem('user_nm'),
    }
  });

  inject('convertor', ( type, value ) => {
    if(type === 'phone'){
      return value
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
    }
    return '';
  });

  inject('dataPattern', (type, value) => {
    if(!value){
      return ''
    }
    if('bizNo' === type){
      return value
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{0,3})(\d{0,2})(\d{0,5})$/g, "$1-$2-$3");
        //.replace(/^(\d{0,3})(\d{0,2})(\d{0,5})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
    }
  });

  inject('dpPatnPrice', (technicalAsset) => {
    if(technicalAsset){
      if(technicalAsset === 0)
        return '0원';
      const flooredAsset = Math.floor(technicalAsset/100000000);
      if(flooredAsset>0){
        return `${flooredAsset.toLocaleString()}억원 대`
      }else{
        return '1억원 미만'
      }
    }
    return '-';
  });

  // 액세스 토큰 추출
  inject('getAccessToken', ()=>{
    return tokenSessionStoragePlugin.getAccessToken(process.client);
  });

  // 리프레시 토큰 추출
  inject('getRefreshToken', ()=>{
    return tokenSessionStoragePlugin.getRefreshToken(process.client);
  });

  // 토큰 리셋
  inject('clearToken', () => {
    tokenSessionStoragePlugin.resetToken(process.client);
  });

  inject('decodeToken', (token) => {
    return tokenSessionStoragePlugin.decodeToken(token);
  });

  inject('alert', payload => {
    const { msg, btn } = payload;
    const _defaultBtn = { name:'확인'};

    let data = {
      state: true,
      type: 'alert',
      msg: `${msg}`,
      btn: btn ? btn : _defaultBtn,
    };
    store.dispatch('ui/setPopupAlertData', data);
  });

  inject('getCompInfo', () => {
    if (process.client) {
      const extData = sessionStorage.getItem('extData');
      return {
        bizNo: sessionStorage.getItem('bizNo'),
        compNm: sessionStorage.getItem('compNm'),
        extData: extData ? JSON.parse(extData) : null
      };
    }else{
      return null;
    }
  });

  // BizNo
  inject('setBizNo', (bizNo) => {
    return sessionStorage.setItem('bizNo', bizNo);
  });
  inject('setCompNm', (compNm) => {
    return sessionStorage.setItem('compNm', compNm);
  });
  inject('getBizNo', () => {
    if (process.client) {
      return sessionStorage.getItem('bizNo');
    }
    return null;
  });

  inject('getCompNm', () => {
    if (process.client) {
      return sessionStorage.getItem('compNm');
    }
    return null;
  });

  inject('fnAlert', (msg, btn) => {
    const _btn = btn ? btn : { name: '확인' };
    let data = {
      state: true,
      type: 'alert',
      msg: `${msg}`,
      btn: _btn
    };
    store.dispatch('ui/setPopupAlertData', data);
  });
  inject('fnConfirm', (msg, btns, title) => {
    const _btns = btns ? btns : [{name:'확인'}, {name:'취소'}]
    const _title = title ? title : null
    store.dispatch('ui/setPopupAlertData', {
      state: true,
      type: 'confirm',
      title: _title,
      msg: msg,
      btns:_btns
    });
  });
  inject('fnClosePopup', ()=>{
    store.dispatch('ui/setPopupAlertData', {
      state:false,
      type: null,
      title: null,
      msg: null,
      btn:{
        link:'',
        name:null,
      }
    });
  })

  inject('fnSaveLog', ($axios, message)=>{
    const bizNo =sessionStorage.getItem('bizNo');
    // console.log(message);
    if($axios){
      const payload = {
        message: `#${bizNo} : ${message}`
      }
      $axios.post('/apiMngAo/customer/tax/hometax/errorLog', payload)
          .then(res => true)
          .catch(err => console.error('save error', err))
    }
  });

  inject('fnSaveErrorLog', ($axios, message, cooconOutput) => {
    const bizNo =sessionStorage.getItem('bizNo');
    const d = new Date();
    if(cooconOutput){
      message = message+' ('+cooconOutput.ErrorCode+') : '+cooconOutput.ErrorMessage;
    }
    const payload = {
      message: `#${bizNo} : ${message}`
    }
    $axios.post('/apiMngAo/customer/tax/hometax/errorLog', payload)
        .then(res => true)
        .catch(err => console.error('save error', err))
  });

  inject('appCheck', () => {
    const isAos = store.state.ui.isAos;
    const isIos = store.state.ui.isIos;
    const isApp = isAos || isIos;
    return { isAos, isIos, isApp }
  });

  inject('isCorporation', (bizNo) => {
    const midNo = parseInt(bizNo.slice(3,5));
    return midNo>=80 && midNo<=88;
  });

  // savedFileNameList - 신규파일과 비교할 파일 목록
  // newFileName - 새롭게 등록할 파일명
  inject('fnFilenameGenerator', (savedFileNameList, newFileName) => {
    const regExFindSameFileName = new RegExp(newFileName);
    const foundSameFileName = savedFileNameList.find( savedFileName => regExFindSameFileName.test(savedFileName));
    if(foundSameFileName){
      const _nfn = newFileName.split('.');
      const fileName = _nfn[0];
      const fileExt = _nfn[1];
      const regExFindMaxNum = new RegExp(fileName+'_(\\d*).'+fileExt);
      let maxNum=0;
      savedFileNameList.forEach( savedFileName => {
        const matchValues = regExFindMaxNum.exec(savedFileName);
        if(matchValues && matchValues.length>=2){
          maxNum = parseInt(matchValues[1]);
        }
      })
      maxNum++;
      return `${fileName}_${maxNum}.${fileExt}`;
    }
    return newFileName;
  });

  inject('fnFormatDate', (d) => {
    const year = d.getFullYear();
    const month = ((d.getMonth()+1)<0) ? '0'+(d.getMonth()+1) : d.getMonth()+1;
    const date = ((d.getDate()+1)<0) ? ('0'+d.getDate()) : d.getDate()
    return `${year}.${month}.${date}`;
  })

  inject('convertCompanyInfo', (raw) => {
    let result = {
      bizNo:raw.bizno,
      compNm: raw.korentrnm,
      compTel: raw.tel,
      prstNm: raw.korreprnm,
      tel: raw.tel,
      fndDt: raw.etbDate,
      labYn: raw.labYN === 'N' ? false : true,
      ventureYn: raw.ventureYn === 'N' ? false : true,
      innobizYn: raw.innobizYn === 'N' ? false : true,
      deptYn: raw.deptYn === 'N' ? false : true,
      certYn: raw.crtYN === 'N' ? false : true,
      patnYn: raw.patentYN === 'N' ? false : true,
      korAddr: raw.koraddr,
      sector: raw.korIdscdnm,
      idscd: raw.idscd,
    };
    if(raw.companyValue){
      result.patnCnt = raw.companyValue.patentCount ? raw.companyValue.patentCount : 0;
      result.techRate = raw.companyValue.tcg ? raw.companyValue.tcg : '-';
      result.patnValue = raw.companyValue.technicalAsset && Number(raw.companyValue.technicalAsset.toFixed(1));
      result.technicalAsset = raw.companyValue.technicalAsset
          ? raw.companyValue.technicalAsset
          : 0;
      if(result.technicalAsset !== 0){
        result.technicalAsset = result.technicalAsset.toFixed(1);
      }
      result.compValue = raw.companyValue.estimatedValue
                         ? Number(raw.companyValue.estimatedValue.toFixed(1))
                         : 0
    } else {
      result.patnCnt =  0;
      result.techRate = '-';
      result.technicalAsset= 0;
      result.patnValue=null;
    }
    return result;
  });

  inject('isMobile', ()=>{
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    return isMobile;
  });

  inject('isPcAndHomePath', ()=> {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    const isHomePath = route.path=='/'
    return !isMobile && isHomePath;
  });
  inject('isPcAndMobileWebAndHomePath', (appCheck)=> {
    // const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

    const isHomePath = route.path=='/'
    return !appCheck.isApp && isHomePath;
  });
}
