<template>
  <div class="app ifs">
    <Header style="z-index: 200;"/>
    <Menu class="nav_mobile"/>
    <nuxt/>
    <Footer style="background-color: #202020;"/>
    <QuickMenu />
    <transition name="lending">
      <Alert v-if="$store.state.ui.popupAlertData.state === true" key="Alert"/>
      <BizNum v-if="$store.state.ui.popupBizNum === true" key="BizNum"/>
      <CompanySelect v-if="$store.state.ui.popupCompanySelect === true" key="CompanySelect" />
      <Mainbiz key="mainBiz" v-if="$store.state.ui.popupMainBiz === true" />
    </transition>
  </div>
</template>

<script>
import Header from "../components/ifs/layouts/Header2";
import Footer from "../components/ifs/layouts/Footer3";
import Alert from "../components/common/Alert";
import BizNum from "../components/ifs/popup/BizNum";
import CompanySelect from "../components/common/popup/CompanySelect";
import Setting from "../mixins/Setting";
import Menu from "../components/ifs/layouts/Menu";
import QuickMenu from "../components/ifs/layouts/QuickMenu";
import Mainbiz from "../components/common/popup/ifs/Mainbiz";
export default {
  name: "partnerLayout",
  mixins:[Setting],
  components: {
    Header,
    Footer,
    BizNum,
    Alert,
    Menu,
    CompanySelect,
    QuickMenu,
    Mainbiz
  },
  computed:{
    myCompList(){
      return this.$store.state.userStore.myCompList;
    }
  },
  created() {
    this.$savePartnerInfo(this.$route);
  },
  mounted() {
    // if(this.$route.fullPath==='/mainbiz')
    //   location.href='/mainbiz/'
    // if(this.$route.fullPath==='/conia')
    //   location.href='/conia/'
    // if(this.$route.fullPath==='/infoyou')
    //   location.href='/infoyou/'
    // if(this.$route.fullPath==='/d3')
    //   location.href='/d3/'
    // if(this.$route.fullPath==='/ktcb')
    //   location.href='/ktcb/'
    //
    this.$auth_RestoreBizNo();
  },
  methods:{
    checkMultipleMyCompList(compList){
      const selectedMyCompBizNo = this.$auth_MyBizNo();
      if( compList && !selectedMyCompBizNo ){
        // 보유하는 회사가 1개
        if(compList.length == 1){
          const bizNo = compList[0].bizNo;
          this.$auth_SaveBizNo(bizNo);
        }
        // 보유하는 회사가 1개 이상
        else if(compList.length > 1){
          // 회사 선택 팝업을 연다
          if( !this.$store.state.ui.popupCompanySelect ){
            this.$store.commit('ui/UPDATE_STATE', {popupCompanySelect:true})
          }
        }
      }
    }
  },
  watch:{
    myCompList(compList){
      this.checkMultipleMyCompList(compList);
    }
  }
}
</script>

<style scoped>
div.app {
  padding-top: 80px;
}

@media screen and (max-width: 770px){
  div.app {
    padding-top: 60px;
  }
}
</style>

